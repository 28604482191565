import React from "react"

const AgreementsPage = () => {
  return (
    <>
      <div className="win-host py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 header-info">
              <h1>Legal Agreements</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row-indent">
          <table
            className="dataTable section agreements-list"
            cellspacing="0"
            width="100%"
            align="center"
            border="0"
          >
            <tbody>
              <tr>
                <td>
                  <div className="ui-heading">Legal Agreements</div>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p>
                    <a href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=privacypolicyagreement&amp;from=agree_page">
                      <b>Privacy Policy</b>
                    </a>
                    <br></br>
                    This is the Privacy Policy that will be applicable to you as
                    our Customer.
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p>
                    <a href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=customermasteragreement&amp;from=agree_page">
                      <b>Customer Master Agreement</b>
                    </a>
                    <br></br>
                    This is the main Customer Master agreement that would apply
                    to you as our Customer. Apart from this Master Agreement,
                    the following Product Specific Agreements may also apply to
                    you depending on the Products and Services you buy.
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p>
                    <a href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=registrarregistrantagreement&amp;from=agree_page">
                      <b>Domain Registration Agreement</b>
                    </a>
                    <br></br>
                    This represents the Agreement between yourself and us for
                    domain registration.
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p>
                    <a href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=registrarregistrantagreementv2&amp;from=agree_page">
                      <b>Registrar Registrant Agreement for Domain Names</b>
                    </a>
                    <br></br>
                    This represents the Agreement between the Registrant (Owner)
                    of a Domain Name and the Registrar. If you register a domain
                    name through us, this Agreement will apply to the person
                    whose information you filled in the Owner section during the
                    Registration process
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p>
                    <a href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=customerdomainagreement&amp;from=agree_page">
                      <b>Customer Agreement for Domain Names</b>
                    </a>
                    <br></br>
                    This represents an addendum to the Customer Master Agreement
                    between yourself and us for Domain Registration
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p>
                    <a href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=customerwebserviceagreement&amp;from=agree_page">
                      <b>Customer Agreement for Web Services</b>
                    </a>
                    <br></br>
                    This represents an addendum to the Customer Master Agreement
                    between yourself and us for Domain / Mail Forwarding and
                    Managed DNS
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p>
                    <a href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=customerdigicertagreement&amp;from=agree_page">
                      <b>Customer Agreement for Digital Certificates</b>
                    </a>
                    <br></br>
                    This represents the Customer Agreement for Digital
                    Certificates
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p>
                    <a href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=customerhostingproductagreement&amp;from=agree_page">
                      <b>Customer Hosting Product Agreement</b>
                    </a>
                    <br></br>
                    This represents the Customer Agreement for Hosting
                  </p>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <p>
                    <a href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=customerthemepluginlogoagreement&amp;from=agree_page">
                      <b>Customer Agreement for Themes, Plugins, Logos</b>
                    </a>
                    <br></br>
                    This represents the Terms and Conditions, Privacy Policy and
                    User License Agreement for Themes, Plugins, Logos
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="footer-img"
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
          >
            <tbody>
              <tr>
                <td>
                  <p>
                    <b>#</b> Registrant shall further endeavour to familiarize
                    themselves with the{" "}
                    <a
                      href="https://www.icann.org/resources/pages/benefits-2013-09-16-en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Registrants' Benefits and Responsibilities{" "}
                    </a>
                    applicable while registering gTLDs.
                  </p>
                  <p>
                    <b>#</b> Registrant is encouraged to refer to ICANN
                    published{" "}
                    <a
                      href="https://www.icann.org/resources/pages/educational-2012-02-25-en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Registrant Educational Materials
                    </a>
                    .
                  </p>
                  <p>
                    <b>#</b>{" "}
                    <a
                      href="https://resellerbazaar.supersite2.myorderbox.com/support/legal.php?requestfor=domainnamedeletionpolicy&from=agree_page"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Domain Name Deletion &amp; Auto-Renewal Policy.
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default AgreementsPage
