import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LegalPage from "../components/legal/agreements/index"
const legal = () => {
  return (
    <Layout>
      <Seo
        title="Best Web hosting Services - Motherepos"
        desc="Motherepos Provides Best Web Hosting Services with 24/7 support"
        pathname="/hosting/"
        keywords="web hosting, shared web hosting, Linux hosting, windows hosting"
      />
      <LegalPage />
    </Layout>
  )
}

export default legal
